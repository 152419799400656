import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Moment from 'react-moment';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/hero.css"

// image svgを圧縮させないため
import bg_top from "../svg/bg_top.svg"
import bg_top_sp from "../images/bg_top_sp.jpeg"
import logo_main from "../svg/logo_main.svg"
import button_group_look from "../svg/button_group_look.svg"
import button_project from "../svg/button_project.svg"
import button_kengai from "../svg/button_kengai.svg"
import button_group_connect_svg from "../svg/button_group_connect.svg"
import button_group_connect from "../images/button_group_connect.png"
import button_event from "../svg/button_event.svg"
import person_akusyu from "../svg/person_akusyu.svg"
import person_banzai3 from "../svg/person_banzai3.svg"
import person_ohuro from "../svg/person_ohuro.svg"
import tottori_dog from "../svg/tottori_dog.svg"

const IndexPage = ({ data, location }) => {
  const craousel_settings = {
    dots: true,
    centerMode: true,
    dots: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
  };

  const craousel_settings_per = {
    className: "bg-white flex flex-col lg:flex-row shadow-md m-4 rounded rounded-lg",
  }

  let pathName = location.pathname

  return (
    <Layout pathName={pathName}>
      <Seo
        title="TOP"
      />

      <section className="hero bg-white">
        <figure>
          <img className="hidden sm:block" src={bg_top} alt="hero" style={{ width: "100%" }} />
          <img className="block sm:hidden" src={bg_top_sp} alt="hero" style={{ width: "100%" }} />
          {/* <StaticImage src="../svg/bg_top.svg" alt="hero" style={{ width: "100%" }} /> */}
        </figure>

        <div className="container max-w-5xl mx-auto catch">
          <div className="w-full flex items-center justify-center px-8 py-4 text-base-dark">
            <Link to={`/`} className="hover:opacity-60 px-1 py-3">
              <img src={logo_main} width="480" height="240" />
            </Link>
          </div>
          <div className="w-full flex items-center justify-center px-2 sm:px-8 py-4 text-base-dark">
            <Link to={`/group/1`} className="hover:opacity-60 px-1 py-3">
              {/* <StaticImage src="../svg/button_group_look.svg" alt="" width={240} /> */}
              <img src={button_group_look} width="240" height="240" />
            </Link>
            <Link to={`/project/1`} className="hover:opacity-60 px-1 py-3">
              {/* <StaticImage src="../svg/button_project.svg" alt="" width={240} /> */}
              <img src={button_project} width="240" height="240" />
            </Link>
            <Link to={`/news-detail/forkengai/`} className="hover:opacity-60 px-1 py-3">
              {/* <StaticImage src="../svg/button_kengai.svg" alt="" width={240} /> */}
              <img src={button_kengai} width="240" height="240" />
            </Link>
          </div>
          <div className="w-full flex items-center justify-center px-8 py-4 text-base-dark">
            <Link to={`/contact`} className="hover:opacity-60 px-1 py-3">
              {/* <StaticImage src="../svg/button_group_connect.svg" alt="" width={240} /> */}
              <img src={button_group_connect_svg} width="240" height="240" />
            </Link>
            <Link to={`/news/1`} className="hover:opacity-60 px-1 py-3">
              {/* <StaticImage src="../svg/button_event.svg" alt="" width={240} /> */}
              <img src={button_event} width="240" height="240" />
            </Link>
          </div>
        </div>
      </section>

      <section className="bg-base-main py-16 mx-8">
        <div className="container max-w-5xl mx-auto">

          <div className="flex justify-around">
            <h2 className="text-2xl sm:text-4xl text-base-dark font-bold leading-none mb-3">とっとりガクチカとは</h2>
          </div>
          <div className="flex justify-center space-x-4">
            <div className="pr-2">
              <img src={person_ohuro} width="180" height="240" />
            </div>
            <div>
              <p className="text-base-dark py-8 text-center text-md">
                「人口最小の県には、最大の可能性とチャンスが広がっている」<br />
                鳥取には、地域に飛び出し、地域を変えようと
                チャレンジする大学生がたくさんいます<br />
                <br />
                そのような地域貢献につながる学生の課外活動を記録し、<br />
                みなさんに届けるのが「とっとりガクチカ」です
              </p>
            </div>
            <div>
              <img src={tottori_dog} width="180" height="240" />
            </div>
          </div>

        </div>
      </section>

      <section className="bg-base-main mx-8">
        <div className="container max-w-5xl mx-auto m-8">

          <div className="flex justify-around">
            <h2 className="text-2xl sm:text-4xl text-base-dark font-bold leading-none mb-3 text-center">
              「とっとりガクチカ」で<br className="block md:hidden"/>
              できること
            </h2>
          </div>

          <div className="flex flex-wrap py-16">
            <div className="w-full flex items-center justify-center py-6">
              <div className="bg-white border-2 border-base-dark rounded-lg overflow-hidden p-2 h-54">
                <h4 className="text-2xl flex items-center justify-center text-main-1 font-bold text-header-3 py-4">団体に出会う</h4>
                <div className="sm:flex">
                  <div className="md:w-2/3 p-8">
                    <div className="mt-2 text-main-1 text-lg">
                      「地域のイベントに出店していたあの団体を知りたい」
                      「新歓で紹介されたサークルのこと、もっと詳しく知りたい」といった場合は、「団体一覧」から見つけることができます。
                    </div>
                  </div>
                  <div className="md:w-1/3 p-4">
                    {/* <StaticImage src="../svg/person_akusyu.svg" alt="" width={240} /> */}
                    <img src={person_akusyu} width="240" height="240" />
                  </div>
                </div>
                <div className="w-full flex items-center justify-center pb-6">
                  <Link to={`group/1`}>
                    <button className="border-2 border-primary-main bg-primary-main text-base-dark hover:opacity-60 rounded-full font-bold text-center px-16 py-3">
                      団体一覧をみる
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="py-6">
              <div className="bg-white border-2 border-base-dark rounded-lg overflow-hidden p-2 h-54">
                <h4 className="text-2xl flex items-center justify-center text-main-1 font-bold text-header-3 py-4">プロジェクトに出会う</h4>
                <div className="sm:flex">
                  <div className="md:w-1/3 px-4">
                    {/* <StaticImage src="../svg/person_banzai3.svg" alt="" width={240} /> */}
                    <img src={person_banzai3} width="240" height="240" />
                  </div>
                  <div className="md:w-2/3 p-8">
                    <div className="mt-2 text-main-1 text-lg">
                      「実際の活動内容から面白そうな団体を見つけたい」
                      といった場合は、より具体的な活動内容に触れることができる「プロジェクト一覧」がおすすめです
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center justify-center pb-6">
                  <Link to={`/project/1`}>
                    <button className="border-2 border-primary-main bg-primary-main text-base-dark hover:opacity-60 rounded-full font-bold text-center px-8 md:px-16 py-3">
                      プロジェクト一覧をみる
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="hidden md:block bg-base-main mx-8">
        <div className="container max-w-5xl mx-auto">

          <div className="flex justify-around">
            <h2 className="text-2xl sm:text-4xl text-base-dark font-bold leading-none mb-3">プロジェクト特集</h2>
          </div>

          <div className="md:p-16">
            <Slider {...craousel_settings}>
              {data.allMicrocmsPortalProject.edges.map(({ node }) => (
                <div>
                  <h3 {...craousel_settings_per}>
                    <img src={node.thumbnail.url} className="m-4" width="320" />
                    <div className="p-8">
                      <span className="text-lg text-base-dark font-bold leading-none">{node.title}</span>
                      <div className="py-4">
                        <span className="bg-base-dark rounded-full font-bold text-md text-white px-4 py-1">
                          <span className="text-xs">{node.category}</span>
                        </span>
                      </div>
                      <div className="flex pt-4">
                        <img src={node.accountName.accountThumbnail.url} width="48" height="48" className="rounded-lg" />
                        <span className="p-4">{node.accountName.accountName}</span>
                      </div>
                    </div>
                  </h3>
                </div>
              ))}
            </Slider>
          </div>

          <div className="w-full flex items-center justify-center py-6">
            <Link to={`/project/1`}>
              <button className="border-2 border-primary-main bg-primary-main text-base-dark hover:opacity-60 rounded-full font-bold text-center px-16 py-3">
                プロジェクト一覧をみる
              </button>
            </Link>
          </div>

        </div>
      </section>

      <section className="bg-base-main py-8 mx-8">
        <div className="container max-w-5xl mx-auto m-8">

          <div className="flex justify-around py-8">
            <h2 className="text-2xl sm:text-4xl text-base-dark font-bold leading-none mb-3">イベント情報・お知らせ</h2>
          </div>

          <div className="py-6 px-4 border-2 border-base-dark bg-white rounded-lg">
            {data.allMicrocmsPortalNews.edges.map(({ node }) => (
              <div className="border-b py-4" key={node.portalNewsId}>
                <div className="text-md text-base-dark">
                  <Moment className="text-md" format="YYYY.MM.DD">{node.createdAt}</Moment>
                  <span className="bg-base-dark rounded-full font-bold text-md text-white mx-2 px-4 py-1">
                    <span className="text-xs">{node.category}</span>
                  </span>
                </div>
                <div className="py-2">
                  <Link to={`/news-detail/${node.portalNewsId}`} className="font-bold text-md hover:underline">
                    {node.title}
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="w-full flex items-center justify-center pt-8">
            <Link to={`/news/1`}>
              <button className="border-2 border-primary-main bg-primary-main text-base-dark hover:opacity-60 rounded-full font-bold text-center px-16 py-3">
                お知らせ一覧をみる
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section className="bg-base-main py-8 mx-8 pb-16">
        <div className="container max-w-5xl mx-auto">

          <div className="flex justify-around">
            <h2 className="text-2xl sm:text-4xl text-base-dark font-bold leading-none mb-3">団体とつながる</h2>
          </div>
          <div className="flex justify-center space-x-4">
            <div>
              <img src={person_ohuro} width="160" height="200" />
            </div>
            <div>
              <p className="text-base-dark text-center py-8 text-lg">
                気になる団体とコラボレーションしましょう！<br></br>
                お問い合わせはこちらから
              </p>
            </div>
            <div>
              <img src={button_group_connect} width="160" height="200" />
            </div>
          </div>
          <div className="w-full flex items-center justify-center py-8">
            <Link to={`/contact/`}>
              <button className="border-2 border-primary-main bg-primary-main text-base-dark hover:opacity-60 rounded-full font-bold text-center px-16 py-3">
                団体とつながる
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allMicrocmsPortalNews(sort: { fields: [createdAt], order: ASC }) {
      totalCount
      edges {
        node {
          title
          category
          portalNewsId
          updatedAt
          createdAt
        }
      }
    }
    allMicrocmsPortalProject(sort: { fields: [createdAt], order: ASC }, limit: 3) {
      totalCount
      edges {
        node {
          updatedAt
          portalProjectId
          title
          accountName {
            accountName
            thumbnail {
              url
            }
            accountThumbnail {
              url
            }
          }
          body
          category
          thumbnail {
            url
          }
        }
      }
    }
  }
`
